nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: fixed;
  top: 0px;
  right: 40px;
  z-index: 3;
  animation: 1s ease-out 0s 1 slideInTop;
}

@media (max-width: 600px) {
  nav {
    position: absolute;
    right: 10px;
    gap: 10px;
  }
}

a {
  text-decoration: none;
}

.line {
  height: 10vh;
  width: 1px;
  background-color: rgb(61, 123, 255);
  box-shadow: rgb(61, 123, 255) 0px 0px 3px;
}

@media (max-width: 600px) {
  .line {
    height: 20px;
  }
}

@keyframes slideInTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.resume {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
}

.download {
  width: 17px;
  filter: invert(80%);
  transition: filter 1st ease-out;
  padding-bottom: 20px;
  transition: all 1s ease-out;
}
