.portfolioDiv {
  display: flex;
  flex-direction: column;
  padding-bottom: 20vh;
}

.portfolioBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.headerDiv {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
  width: 80vw;
  padding-bottom: 50px;
}

.portfolioHeader {
  color: rgb(61, 123, 255);
  font-size: 3rem;
}

.line {
  height: 2px;
  width: 500px;
  background-color: rgb(61, 123, 255);
  box-shadow: rgb(61, 123, 255) 2px 2px 2px;
}
@media (max-width: 600px) {
  .line {
    display: none;
  }
}
.projectList {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.card {
  width: 70vw;
  display: flex;
  align-items: flex-start;
  padding-bottom: 40px;
  gap: 20px;
  max-width: 800px;
  padding: 20px;
}

@media (max-width: 600px) {
  .card {
    align-items: center;
    flex-direction: column;
  }
  .projectHeader {
    flex-direction: column;
    gap: 10px;
  }
}

.projectHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkGroup {
  display: flex;
  align-items: center;
  gap: 20px;
}

.textBlock {
  padding: 10px;
}

.projectTitle {
  font-size: 1.5rem;
  color: rgb(61, 123, 255);
}

.linkIcon {
  width: 20px;
  filter: invert(50%);
  transition: filter 1s ease-out;
}

.linkIcon:hover,
.linkIcon:focus {
  filter: invert(100%);
}

.linkSet {
  display: flex;
  align-items: center;
  gap: 8px;
}

.projectImg {
  width: 220px;
  filter: brightness(40%);
}

.projectImg:hover {
  filter: brightness(100%);
}

a {
  color: rgb(180, 180, 180);
  font-style: normal;
  position: relative;
  bottom: 0px;
  transition: color 1s ease-out;
}

a:hover,
a:focus {
  color: rgb(61, 123, 255);
  bottom: 1px;
  text-shadow: rgb(61, 123, 255) 0px 0px 1px;
}
