.AppDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 120%;
}

.sections {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 800px;
}

@media (max-width: 600px) {
  .AppDiv {
    align-items: stretch;
    padding-left: 40px;
    padding-right: 40px;
  }
  .sections {
    align-items: flex-end;
  }
}

li {
  list-style: square;
}
