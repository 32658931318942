.contactDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 130%;
  padding-bottom: 20vh;
}

.headerDiv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 20px;
}

.contactHeader {
  color: rgb(61, 123, 255);
  font-size: 3rem;
}

.contactText {
  max-width: 550px;
}

.contactButtonDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.contactButton {
  border: solid rgb(61, 123, 255) 2px;
  padding: 10px;
}

.line {
  height: 2px;
  width: 500px;
  background-color: rgb(61, 123, 255);
}

@media (max-width: 600px) {
  .line {
    display: none;
  }
}

.addressBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
